<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />
    <div class="col-md-12" style="margin-top:-15px;padding: 14px;" >
      <div class="row">
        <div class="col-md-8">
          <h2 class="titulo-links-escolha">Boletim de Notas</h2>
        </div>
        <div class="col-md-4">
        </div>
      </div>

      <painelFiltro modelo="0" individual="0" :objeto="false" @buscarRelatorio="buscarRelatorio" :filtro="filtro" />

      <section v-if="visivel">
        <div class="row" style="background-color: #f8f9fa;border: 1px solid #dee2e6;margin-top:15px;padding-bottom: 15px;">
          <div class="col-md-2">
            <label class="ft">Exibir por :</label>
            <select class="p-inputtext p-component"
              style="appearance: revert !important;width: 100% !important;"
              v-model="exibir" @change="this.disciplina_id = 0;gerarBoletim(exibir = exibir);getDisciplinas();">
              <option value="turma" >Turma</option>
              <option value="aluno" >Alunos</option>
            </select>
          </div>

          <div class="col-md-3">
            <label class="ft">Escolha os Períodos de exibição:</label>
            <select class="p-inputtext p-component"
              style="appearance: revert !important;width: 100% !important;"
              v-model="periodo" @change="this.disciplina_id = 0;gerarBoletim(this.exibir); getDisciplinas(); this.avaliacao='notalfinal'" >
              <option v-for="a in periodoALL" :key="a" :value="a" >{{a}}</option>
            </select>
          </div>

          <div class="col-md-2" v-if="exibir == 'turma' && periodo != 'Completo' ">
            <label>Escolha a exibição da nota:</label>
            <select class="p-inputtext p-component"
              style="appearance: revert !important;width: 100% !important;"
              v-model="avaliacao" >
              <option value="notalfinal" >Nota final</option>
              <option value="completo" >Completo</option>
            </select>
          </div>

          <div class="col-md-2" v-if="exibir == 'turma'">
            <label>Escolha a disciplina:</label>
            <select class="p-inputtext p-component"
              style="appearance: revert !important;width: 100% !important;" @change="gerarBoletim(exibir = exibir);"
              v-model="disciplina_id" >
              <option v-for="a in disciplinaALL" :key="a.id" :value="a.id" >{{a.nome}}</option>
            </select>
          </div>
        </div>
      </section>
    </div>


    <div class="pm-card" v-if="visivel">
      <div class="p-fluid formgrid grid" >

        <!-- <div class="col-md-2" v-if="exibir != 'turma'" style="margin-bottom: 20px;">
          <label>Escolha o Aluno:</label>
          <select class="p-inputtext p-component"
            style="appearance: revert !important;width: 100% !important;"
            v-model="aluno_id" >
            <option v-for="a in boletimALL" :key="a.aluno_id" :value="a.aluno_id" >{{a.nome}} {{ a.sobrenome_aluno }}</option>
          </select>
        </div> -->

        <div class=" field col-12 md:col-12">
          <button class="btn btn-danger" @click="gerarPdf()" style="margin-top:10px;margin-right:5px;"
            title="Gerar Impressão">
            <i class="fa fa-file-pdf-o"></i> Pdf
          </button>

          <button class="btn btn-success" @click="gerarExcel()" style="margin-top:10px;"
            title="Gerar Excel">
            <i class="fa fa-file-excel-o"></i> Excel
          </button>

          <button class="btn btn-secondary ml-1" @click="gerarCsv('dadosrel')" style="margin-top:10px;"
            title="Gerar csv">
            <i class="fa fa-file-o"></i> CSV
          </button>
        </div>

        <div class="col-md-12" id="dadosrel">

          <head>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
            <meta charset="utf-8">
          </head>

          <div class="col-md-12" style="padding-top:20px;" v-if="!isLoading">
            <div v-if="exibir === 'turma'">

              <div class="mb-3">
                <label  class="col-12 flex justify-content-center m-0 header">Boletim - {{ boletimALL.alunos[0].ano }}</label>
                <h1  class="col-12 flex justify-content-center m-0 header">{{ boletimALL.alunos[0].escola_nome }}</h1>
                <label  class="col-12 flex justify-content-center m-0 header">{{ boletimALL.alunos[0].segmento_nome }}</label>
                <label  class="col-12 flex justify-content-center m-0 header">{{ boletimALL.alunos[0].serie_nome }} - {{ boletimALL.alunos[0].turma_nome }}</label>
              </div>
              <table v-if="exibir === 'turma'" class="borda-externa table table-bordered" border=1 cellpadding=15  cellspacing=15 cellmargin="15">

                <thead v-if="periodo == 'Completo' && exibir == 'turma'">
                  <tr>
                    <th class="th-the" colspan="2" cellpadding=15> Alunos </th>
                    <th class="th-the" colspan="2" cellpadding=15> 1° Período </th>
                    <th class="th-the" colspan="2" cellpadding=15> Frequência</th>
                    <th class="th-the" colspan="2" cellpadding=15> 2° Período </th>
                    <th class="th-the" colspan="2" cellpadding=15> Frequência</th>
                    <th class="th-the" colspan="2" cellpadding=15> 3° Período </th>
                    <th class="th-the" colspan="2" cellpadding=15> Frequência</th>
                    <th class="th-the" colspan="2" cellpadding=15> 4° Período </th>
                    <th class="th-the" colspan="2" cellpadding=15> Frequência</th>
                    <th class="th-the" colspan="2" cellpadding=15> Média Final </th>
                    <th class="th-the" colspan="2" cellpadding=15> Frequência </th>
                  </tr>
                </thead>

                <thead v-if="periodo != 'Completo' && exibir == 'turma' && avaliacao == 'notalfinal'">
                  <tr>
                    <th class="th-the" colspan="2" cellpadding=15> Alunos </th>
                    <th class="th-the" colspan="2" cellpadding=15> {{ periodo }} </th>
                    <th class="th-the" colspan="2" cellpadding=15> Frenquência  </th>
                  </tr>
                </thead>

                <thead v-if="periodo != 'Completo' && exibir == 'turma' && avaliacao != 'notalfinal'">
                  <tr>
                    <th class="th-the" colspan="2" cellpadding=15> Alunos </th>
                    <th class="th-the" colspan="2" cellpadding=15> Simulado </th>
                    <th class="th-the" colspan="2" cellpadding=15> Atividade </th>
                    <th class="th-the" colspan="2" cellpadding=15> Avaliação </th>
                    <th class="th-the" colspan="2" cellpadding=15> {{ periodo }} </th>
                    <th class="th-the" colspan="2" cellpadding=15> Frequência </th>
                  </tr>
                </thead>

                <thead v-if="periodo == 'Completo' && exibir == 'aluno'">
                  <tr>
                    <th class="th-the" colspan="2" cellpadding=15> Disciplinas </th>
                    <th class="th-the" colspan="2" cellpadding=15> 1° Período </th>
                    <th class="th-the" colspan="2" cellpadding=15> 2° Período </th>
                    <th class="th-the" colspan="2" cellpadding=15> 3° Período </th>
                    <th class="th-the" colspan="2" cellpadding=15> 4° Período </th>
                    <th class="th-the" colspan="2" cellpadding=15> Média Final </th>
                  </tr>
                </thead>

                <thead v-if="periodo != 'Completo' && exibir == 'aluno'">
                  <tr>
                    <th class="th-the" colspan="2"> Disciplinas </th>
                    <th class="th-the" colspan="2"> {{ periodo }} </th>
                  </tr>
                </thead>

                <tbody v-if="exibir == 'turma' && avaliacao == 'notalfinal' &&  this.disciplina_id != 0">
                  <tr v-for="a in boletimALL.alunos" :key="a" >
                    <td class="th-tbo" colspan="2"> {{ a.nome }} {{ a.sobrenome_aluno }}</td>
                    <td class="th-tbo" colspan="2" v-if="periodo == 'Completo' || periodo == '1° Período'" align="center" style="background-color: #e0e0e0;"> {{ a.notas[0].p1.periodo }} </td>
                    <td class="th-tbo" colspan="2" v-if="periodo == 'Completo' || periodo == '1° Período'" align="center" style="background-color: #e0e0e0;"> {{a.notas[0].p1.frequencia.percentual}}%</td>
                    <td class="th-tbo" colspan="2" v-if="periodo == 'Completo' || periodo == '2° Período'" align="center" > {{ a.notas[0].p2.periodo }} </td>
                    <td class="th-tbo" colspan="2" v-if="periodo == 'Completo' || periodo == '2° Período'" align="center"> {{a.notas[0].p2.frequencia.percentual}}%</td>
                    <td class="th-tbo" colspan="2" v-if="periodo == 'Completo' || periodo == '3° Período'" align="center" style="background-color: #e0e0e0;"> {{ a.notas[0].p3.periodo }} </td>
                    <td class="th-tbo" colspan="2" v-if="periodo == 'Completo' || periodo == '3° Período'" align="center" style="background-color: #e0e0e0;"> {{a.notas[0].p3.frequencia.percentual}}%</td>
                    <td class="th-tbo" colspan="2" v-if="periodo == 'Completo' || periodo == '4° Período'" align="center"> {{ a.notas[0].p4.periodo }} </td>
                    <td class="th-tbo" colspan="2" v-if="periodo == 'Completo' || periodo == '4° Período'" align="center"> {{a.notas[0].p4.frequencia.percentual}}%</td>
                    <td class="th-tbo" colspan="2" v-if="periodo == 'Completo'" align="center" style="background-color: #e0e0e0;">{{ a.notas[0].media_final }} </td>
                    <td class="th-tbo" colspan="2" v-if="periodo == 'Completo'" align="center" style="background-color: #e0e0e0;"> {{a.notas[0].frequencia_final}} </td>
                  </tr>
                </tbody>

                <tbody v-if="exibir == 'turma' && avaliacao != 'notalfinal' && this.disciplina_id != 0">

                  <tr v-for="a in boletimALL.alunos" :key="a" v-if="periodo == '1° Período'" >
                    <td class="th-tbo" colspan="2" align="center"> {{ a.nome }} {{ a.sobrenome_aluno }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p1.simulado }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p1.atividades }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p1.avaliacao }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p1.periodo }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p1.frequencia.percentual }} %</td>
                  </tr>

                  <tr v-for="a in boletimALL.alunos" :key="a" v-if="periodo == '2° Período'" >
                    <td class="th-tbo" colspan="2" align="center"> {{ a.nome }} {{ a.sobrenome_aluno }}</td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p2.simulado }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p2.atividades }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p2.avaliacao }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p2.periodo }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p2.frequencia.percentual }} %</td>
                  </tr>

                  <tr v-for="a in boletimALL.alunos" :key="a" v-if="periodo == '3° Período'" >
                    <td class="th-tbo" colspan="2" align="center"> {{ a.nome }} {{ a.sobrenome_aluno }}</td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p3.simulado }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p3.atividades }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p3.avaliacao }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p3.periodo }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p3.frequencia.percentual }} % </td>
                  </tr>

                  <tr v-for="a in boletimALL.alunos" :key="a" v-if="periodo == '4° Período'" >
                    <td class="th-tbo" colspan="2" align="center"> {{ a.nome }} {{ a.sobrenome_aluno }}</td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p4.simulado }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p4.atividades }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p4.avaliacao }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p4.periodo }} </td>
                    <td class="th-tbo" colspan="2" align="center"> {{ a.notas[0].p4.frequencia.percentual }} % </td>
                  </tr>
                </tbody>

                <tbody  v-for="a in boletimALL.alunos" v-if="exibir == 'aluno'">
                  <tr v-for="n in a.notas" :key="n" v-if="a.aluno_id == aluno_id">
                    <td class="th-tbo" colspan="2" align="center"> {{ n.disciplina }} </td>
                    <td class="th-tbo" colspan="2" align="center" v-if="periodo == 'Completo' || periodo == '1° Período'"> {{ n.p1.periodo }} </td>
                    <td class="th-tbo" colspan="2" align="center" v-if="periodo == 'Completo' || periodo == '2° Período'"> {{ n.p2.periodo }} </td>
                    <td class="th-tbo" colspan="2" align="center" v-if="periodo == 'Completo' || periodo == '3° Período'"> {{ n.p3.periodo }} </td>
                    <td class="th-tbo" colspan="2" align="center" v-if="periodo == 'Completo' || periodo == '4° Período'"> {{ n.p4.periodo }} </td>
                    <td class="th-tbo" colspan="2" align="center" v-if="periodo == 'Completo' || periodo == '4° Período'">{{ n.media_final }} </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-for="(aluno, index) in boletimALL.alunos" :key="index" class="table-separate-boletim" v-show="exibir === 'aluno'">
              <div class="">
                <label  class="col-12 flex justify-content-center m-0 header">Boletim - {{ boletimALL.alunos[0].ano }}</label>
                <h1  class="col-12 flex justify-content-center m-0 header">{{ boletimALL.alunos[0].escola_nome }}</h1>
                <label  class="col-12 flex justify-content-center m-0 header">{{ boletimALL.alunos[0].segmento_nome }}</label>
                <label  class="col-12 flex justify-content-center m-0 header">{{ boletimALL.alunos[0].serie_nome }} - {{ boletimALL.alunos[0].turma_nome }}</label>
              </div>

              <h5 style="font-size:14px;"><b> Nome </b> {{ aluno.nome }} {{ aluno.sobrenome_aluno }} </h5>

              <table  v-if="exibir === 'aluno'" class="borda-externa col-12 mb-5 ">
                <thead v-if="periodo === 'Completo' && boletimALL.segmento.tipo != 3">
                  <tr>
                    <th class="th-the bordas title-padding" > Disciplinas </th>
                    <th class="th-the bordas title-padding" > 1° Período </th>
                    <th class="th-the bordas title-padding" > Frequência </th>
                    <th class="th-the bordas title-padding" > 2° Período </th>
                    <th class="th-the bordas title-padding" > Frequência </th>
                    <th class="th-the bordas title-padding" > 3° Período </th>
                    <th class="th-the bordas title-padding" > Frequência </th>
                    <th class="th-the bordas title-padding" > 4° Período </th>
                    <th class="th-the bordas title-padding" > Frequência </th>
                    <th class="th-the bordas title-padding" > Média Final </th>
                    <th class="th-the bordas title-padding" > Frequência </th>

                  </tr>
                </thead>
                <thead v-if="periodo === 'Completo' && boletimALL.segmento.tipo === 3">
                  <tr>
                    <th class="th-the bordas title-padding" > Disciplinas </th>
                    <th class="th-the bordas title-padding" > 1° Período </th>
                    <th class="th-the bordas title-padding" > Frequência </th>
                    <th class="th-the bordas title-padding" > 2° Período </th>
                    <th class="th-the bordas title-padding" > Frequência </th>
                    <!-- <th class="th-the bordas title-padding" > 3° Período </th>
                    <th class="th-the bordas title-padding" > Frequência </th>
                    <th class="th-the bordas title-padding" > 4° Período </th>
                    <th class="th-the bordas title-padding" > Frequência </th> -->
                    <th class="th-the bordas title-padding" > Média Final </th>
                    <th class="th-the bordas title-padding" > Frequência </th>

                  </tr>
                </thead>

                <thead v-if="periodo != 'Completo'">
                  <tr>
                    <th class="th-the" > Disciplinas </th>
                    <th class="th-the" > {{ periodo }} </th>
                    <th class="th-the" > Frequência </th>
                    <th v-if="periodo === '4° Período'" class="th-the" > Média Final </th>
                    <th v-if="periodo === '4° Período'" class="th-the" > frequencia final </th>
                  </tr>
                </thead>

                <tbody class="borda-externa " v-if="boletimALL.segmento.tipo != 3">
                  <tr v-for="n in aluno.notas" :key="n" class="">
                    <td class="th-tbo bordas" align="center"> {{ n.disciplina }} </td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '1° Período'" style="background-color: #e0e0e0;"> {{ n.p1.periodo }} </td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '1° Período'" style="background-color: #e0e0e0;"> {{ n.p1.frequencia.percentual }} %</td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '2° Período'"> {{ n.p2.periodo }} </td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '2° Período'"> {{ n.p2.frequencia.percentual }} %</td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '3° Período'" style="background-color: #e0e0e0;"> {{ n.p3.periodo }} </td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '3° Período'" style="background-color: #e0e0e0;"> {{ n.p3.frequencia.percentual }} %</td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '4° Período'"> {{ n.p4.periodo }} </td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '4° Período'"> {{ n.p4.frequencia.percentual }} %</td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '4° Período'" style="background-color: #e0e0e0;" :style="n.media_final < 50 ? 'color: red' : 'color:blue'">{{ n.media_final }} </td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '4° Período'" style="background-color: #e0e0e0;">{{n.frequencia_final}}</td>
                  </tr>
                </tbody>
                <tbody class="borda-externa " v-if="boletimALL.segmento.tipo === 3">
                  <tr v-for="n in aluno.notas" :key="n" class="">
                    <td class="th-tbo bordas" align="center"> {{ n.disciplina }} </td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '1° Período'" style="background-color: #e0e0e0;"> {{ n.p1.periodo }} </td>
                    <td class="th-tbo bordas" align="center" v-if="(periodo == 'Completo' || periodo == '1° Período') && boletimALL.semestre === 1" style="background-color: #e0e0e0;"> {{ n.p1.frequencia.percentual }} %</td>
                    <td class="th-tbo bordas" align="center" v-if="(periodo == 'Completo' || periodo == '1° Período') && boletimALL.semestre === 2" style="background-color: #e0e0e0;"> {{ n.p3.frequencia.percentual }} %</td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '2° Período'"> {{ n.p2.periodo }} </td>
                    <td class="th-tbo bordas" align="center" v-if="(periodo == 'Completo' || periodo == '2° Período') && boletimALL.semestre === 1"> {{ n.p2.frequencia.percentual }} %</td>
                    <td class="th-tbo bordas" align="center" v-if="(periodo == 'Completo' || periodo == '2° Período') && boletimALL.semestre === 2"> {{ n.p4.frequencia.percentual }} %</td>
                    <!-- <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '3° Período'" style="background-color: #e0e0e0;"> {{ n.p3.periodo }} </td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '3° Período'" style="background-color: #e0e0e0;"> {{ n.p3.frequencia.percentual }} %</td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '4° Período'"> {{ n.p4.periodo }} </td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '4° Período'"> {{ n.p4.frequencia.percentual }} %</td> -->
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '4° Período'" :style="n.media_final < 50 ? 'color: red' : 'color:blue'" style="background-color: #e0e0e0;">{{ n.media_final }} </td>
                    <td class="th-tbo bordas" align="center" v-if="periodo == 'Completo' || periodo == '4° Período'" style="background-color: #e0e0e0;">{{n.frequencia_final}}</td>
                  </tr>
                </tbody>

              </table>
            </div>
          </div>

          <div class="mt-5 flex justify-content-center" v-if="isLoading">
            <pm-ProgressSpinner />
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import axios from 'axios';
import painelFiltro from "./components/filtros/painelFiltro.vue";
import { DisciplinaEscolar } from "@/class/disciplina";

export default defineComponent ({
  components:{painelFiltro},
  data(){
    return{
      base_url: axios.defaults.baseURL.replace('/api/v1',''),
      home: {icon: 'pi pi-home', to: '/relatorios/boletim-notas'},
      items_bread: [
        {label: 'Boletim de notas'},
      ],
      periodoALL:['Completo','1° Período','2° Período','3° Período','4° Período'],
      periodo:'Completo',
      dados:{},
      exibir:'aluno',
      avaliacao: 'notalfinal',
      boletimALL: [],
      disciplinaALL: [],
      aluno_id:1,
      turma_id:0,
      visivel: false,
      disciplina_id: 0,
      isLoading:false,
      prevRoute: null
    }
  },
  async beforeMount() {document.getElementById('printme').innerHTML = '';},
  methods: {
    gerarCsv(id) {
      // Obtém a div pelo id
      const div = document.getElementById(id);
      if (!div) {
        console.error('Div não encontrada: ' + id);
        return;
      }

      // Obtém todas as linhas da div
      const linhas = div.querySelectorAll('tr');

      // Cria o conteúdo do CSV
      let csv = '';
      linhas.forEach((linha) => {
        const celulas = linha.querySelectorAll('td');
        const valores = Array.from(celulas).map((celula) => celula.textContent);
        csv += valores.join(',');
        csv += '\n';
      });

      // Cria um Blob com o conteúdo do CSV
      const csvString = "\uFEFF" + csv;
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

      // Cria uma URL para o Blob
      const url = URL.createObjectURL(blob);

      // Cria um link para download do arquivo CSV
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'boletimNotas.csv');

      // Adiciona o link ao corpo do documento e inicia o download
      document.body.appendChild(link);
      link.click();

      // Remove o link do corpo do documento
      document.body.removeChild(link);
    },
    gerarBoletim(tipo){

      this.boletimALL = [];
      const payload = {
        "turma_id": this.turma_id,
        "disciplina_id": this.disciplina_id,
      }
      this.isLoading = true;
      axios.get('/boletim-aluno',{
          params: {
            disciplina_id: payload.disciplina_id,
            turma_id: payload.turma_id,
          }
        })
        .then((resp) => {
          this.boletimALL = resp.data;
          this.aluno_id = this.boletimALL[0].aluno_id
          this.dados = this.boletimALL;
          this.isLoading = false;
        }).catch((err) => {
          this.isLoading = false;
        }).finally(()=>{
          this.isLoading = false;
        });

    },
    async getDisciplinas(){
      const data = await DisciplinaEscolar.obtemTodosSomenteTrue2(this.turma_id);
      this.disciplinaALL = data.data.disciplinas_turma
    },
    async buscarRelatorio(info){
     this.dados = info;
     this.turma_id = info.turma_id;
     this.gerarBoletim(this.exibir);
     await this.getDisciplinas();
     this.disciplina = this.disciplinaALL[0].id;
     this.visivel = true;
    },
    gerarPdf(){
      const div = document.getElementById("dadosrel");
      const divp = document.getElementById("printme");
      if (divp.innerHTML) {
        divp.innerHTML = "";
      }

      if (div != null) {
        divp.innerHTML = div.innerHTML;
      }

      window.print();
    },
    gerarExcel(){
      const div = document.getElementById('dadosrel');
      const divp = document.getElementById("printme");

      if (div != null) {
        divp.innerHTML = div.innerHTML;
      }

      const a = document.createElement('a');
      const data_type = 'data:application/vnd.ms-excel';
      const table_html = divp.outerHTML.replace(/ /g, '%20');

      a.href = data_type + ', ' + table_html;
      a.download = 'boletimdenotas.xls';
      a.click();
    },

  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
      const divAta = document.getElementById("print");

      if ((vm.prevRoute.name === "atas" || vm.prevRoute.name === "ataAlunos") && divAta) {
        location.reload();
      }
    })
  },
});
</script>

<style>
.font-table{
  font-size: 20px;
}

.header{
  font-size: 14px;
  margin: 0px;
  padding: 0px;
}

.borda-externa {
  border: 2px #000 solid;
}
.bordas {
  border: 1px solid #000;
}

.title-padding {
  padding: 0px 5px 0px 5px;
}
.tabela {
  max-height: 2px;
}
.th-the{
  background-color: #292828 !important;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
}
.th-tbo{

  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
}
</style>

<style>
#noprint {
  display: block;
}
#printme {
  display: none;
}

@media print {

  @page{
    /* size: A4 portrait !important; */
    /* break-inside: avoid; */
    /* page-break-inside: avoid; */
  }
  .table-separate-boletim{
    break-inside: avoid !important;
    page-break-inside: avoid !important;
  }
  #noprint {
    display: none;
  }
  #printme {
    display: block;
  }
}
</style>

<style>
.ft {
  font-size: 15px!important;
}
</style>



